import { v4 } from 'uuid'
import { PedidoLog } from './PedidoLog'
import { currencyFormatter } from '../utils/formatter'

export class Pedido {
  constructor ({
    id,
    pedido_id,
    user_id,
    nome_produtor,
    nome_fazenda,
    statusPrevious,
    status,
    data_pedido,
    parcelas,
    valor_total,
    observacao,
    resposta,
    data_prevista_entrega,
    tipo_atendimento,
    data_atendimento,
    observacao_atendimento,
    signature_id,
    itens,
    logs,
  } = {
    id: v4(),
    pedido_id: '',
    user_id: 0,
    nome_produtor: '',
    nome_fazenda: '',
    statusPrevious: '',
    status: '',
    data_pedido: '',
    parcelas: 0,
    valor_total: 0,
    observacao: '',
    resposta: '',
    data_prevista_entrega: '',
    tipo_atendimento: '',
    data_atendimento: '',
    observacao_atendimento: '',
    signature_id: '',
    itens: [],
    logs: [],
  }) {
    this.id = id
    this.pedido_id = pedido_id
    this.user_id = user_id
    this.nome_produtor = nome_produtor
    this.nome_fazenda = nome_fazenda
    this.statusPrevious = statusPrevious
    this.status = status
    this.data_pedido = data_pedido
    this.parcelas = parcelas
    this.valor_total = valor_total
    this.observacao = observacao
    this.resposta = resposta
    this.data_prevista_entrega = data_prevista_entrega
    this.tipo_atendimento = tipo_atendimento
    this.data_atendimento = data_atendimento
    this.observacao_atendimento = observacao_atendimento
    this.signature_id = signature_id

    this.itens = itens.map(({ ...item }) => {
      return {
        ...item,
        valor_unit: currencyFormatter(item.valor_desconto && item.valor_desconto > 0 ? item.valor_desconto : item.valor_unitario),
        valor_total: currencyFormatter(item.quantidade * (item.valor_desconto && item.valor_desconto > 0 ? item.valor_desconto : item.valor_unitario)),
      }
    })
    this.logs = logs.map(({ ...rest }) => new PedidoLog(({
      ...rest,
    })))
  }
}
