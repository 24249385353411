<template>
  <div class="content">
    <div class="row justify-content-center">
      <div class="col-12">
        <v-dialog
          v-model="dialog"
          persistent
          max-width="410px"
        >
          <v-form ref="form">
            <v-card>
              <v-toolbar
                color="primary"
                dark
              >
                <span class="headline">Insira o {{ validar }} do(a) {{ titulo }}</span>
              </v-toolbar>
              <v-card-text class="pb-0">
                <v-container>
                  <crud-cpf-cnpj
                    ref="cpf_cnpj"
                    v-model="cpf_cnpj"
                    required
                    :texto="validar"
                    @focus="notFound = false"
                  />
                  <v-alert
                    v-show="notFound"
                    dense
                    text
                    type="error"
                    class="pa-0 mb-0"
                  >
                    Pessoa não encontrada no sistema
                  </v-alert>
                  <!-- </v-scale-transition> -->
                </v-container>
              </v-card-text>
              <v-card-actions class="justify-center pb-3">
                <v-btn
                  class="text-none ms-4 text-white"
                  color="error"
                  rounded
                  @click="cancel"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-minus-circle
                  </v-icon>
                  Cancelar
                </v-btn>
                <v-btn
                  class="text-none ms-4 text-white"
                  color="success"
                  :loading="searching"
                  rounded
                  @click="submit"
                >
                  <v-icon
                    dark
                    left
                  >
                    {{ !notFound ? 'mdi-magnify' : 'mdi-file-plus' }}
                  </v-icon>
                  {{ !notFound ? 'Pesquisar' : 'Cadastrar' }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </div>
    </div>
  </div>
</template>
<script>
  import rules from '../../utils/formRules'
  export default {
    name: 'CPFDialog',
    props: {
      dialog: {
        type: Boolean,
        default: false,
      },
      titulo: {
        type: String,
        default: 'Produtor',
      },
      store: {
        type: String,
        default: 'produtores',
      },
      validar: {
        type: String,
        default: 'CPF/CNPJ',
      },
      profile: {
        type: String,
        default: 'produtor',
      },
    },
    data () {
      return {
        cpf_cnpj: null,
        searching: false,
        notFound: false,
        rules,
      }
    },
    computed: {
    },
    mounted () {
      if (!this.$store.state[this.store][this.store]) {
        this.Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Por favor, acesse a página de edição por meio da página de listagem',
        })
        this.cancel()
      }
    },
    methods: {
      cancel () {
        this.$router.push({ path: `/${this.$user.get().role}/${this.store}` })
      },
      checkList (store, pessoa) {
        return this.$store.state[store][store].some(obj => {
          return obj.cpf_cnpj === pessoa
        })
      },
      submit () {
        if (this.$refs.cpf_cnpj.validate()) {
          const { tipo, value } = this.cpf_cnpj

          if (this.notFound) {
            this.notFound = false
            this.$emit('close-dialog', this.cpf_cnpj)
            return
          }

          this.searching = true
          this.api.getPessoa(
            { [tipo]: value },
          ).then(
            result => {
              const { correntUser } = result
              this.searching = false
              if (!this.checkList(this.store, this.cpf_cnpj.value)) {
                this.Swal.fire({
                  icon: 'error',
                  title: 'Erro',
                  text: 'Usuário já cadastrado em outra unidade de negócio!',
                })
              } else if (correntUser && correntUser.roles.find(role => role.slug === this.profile)) {
                this.$emit('close-dialog', result)
              } else {
                this.Swal.fire({
                  icon: 'error',
                  title: 'Erro',
                  text: 'Usuário encontrado no sistema com outro papel!',
                })
              }
            },
            () => {
              this.searching = false
              this.notFound = true
            },
          )
        }
      },
    },
  }
</script>
