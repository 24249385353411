import { getField, updateField } from 'vuex-map-fields'
import api from '@/api/api'
// import router from '../../../Routes/router'
import Swal from 'sweetalert2'
// import { v4 } from 'uuid'
import { profileTransformer } from '@/utils/transformers'

export const SET_STATE = 'SET_STATE'
export const BOOTSTRAP_PROFILE = 'BOOTSTRAP_PROFILE'
export const LOAD_NOTIFICATIONS = 'LOAD_NOTIFICATIONS'
export const LOAD_UNIDADES = 'LOAD_UNIDADES'
export const LOAD_FAZENDAS = 'LOAD_FAZENDAS'
export const LOAD_ROLES = 'LOAD_ROLES'
export const SUBMIT = 'SUBMIT'
export const UPDPASSWORD = 'UPDPASSWORD'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    telefones: [
      { tipo: 'Comercial', format: 'com', title: 'Telefone 1' },
      { tipo: 'Comercial', format: 'com', title: 'Telefone 2' },
    ],
    loading: true,
    cadastros: null,
    pedidos_aguardando: null,
    pedidos_em_analise: null,
    pedidos_aprovados: null,
    pedidos_faturados: null,
    pedidos_cancelados_slcnc: null,
    cotacoes: null,
    cotacoes_slcnc: null,
    isEditing: false,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [BOOTSTRAP_PROFILE] ({ dispatch, commit, state }) {
    commit(SET_STATE, { loading: true })
    api.getProfile().then(response => {
      dispatch('form/BOOTSTRAP_FORM', { model: profileTransformer(response) }, { root: true })
      commit(SET_STATE, { loading: false, isEditing: true })
    })
  },
  async [LOAD_NOTIFICATIONS] ({ commit, rootState }) {
    if (rootState.user.roles[0].slug === 'gestor' || rootState.user.roles[0].slug === 'supervisor') {
      const notifications = (await api.listEntidade(`${rootState.user.roles[0].slug}/notification`))
      commit(SET_STATE, {
        cadastros: notifications[0],
        pedidos_aguardando: notifications[1],
        pedidos_aprovados: notifications[2],
        pedidos_em_analise: notifications[3],
        pedidos_faturados: notifications[4],
        pedidos_cancelado_slcnc: notifications[5],
        cotacoes: notifications[6],
        cotacoes_slcnc: notifications[7],
      })
    } else {
      commit(SET_STATE, {
        cadastros: 0,
        pedidos_aguardando: 0,
        pedidos_aprovados: 0,
        pedidos_em_analise: 0,
        pedidos_faturados: 0,
        pedidos_cancelados_slcnc: 0,
        cotacoes: 0,
        cotacoes_slcnc: 0,
      })
    }
  },
  async [UPDPASSWORD] ({ rootState }, { router, user }) {
    const { data, ...formSenha } = rootState.form

    Swal.fire({
      title: 'Enviando dados!',
      timerProgressBar: true,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading()
      },
      didOpen: () => {
        api.updatePassword(formSenha).then(
          data => {
            Swal.fire({
              title: 'Senha atualizado com sucesso!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
            router.push({
              path: `/${user.get().role}/dashboard`,
            })
          },
          ({ errors = null, message }) => {
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              text: errors || message,
            })
          },
        )
      },
    })
  },
  async [SUBMIT] ({ dispatch, state, rootState }, { router, user }) {
    const { data, ...form } = rootState.form.data

    if (form.data_nascimento) {
      const [day, month, year] = form.data_nascimento.split('/')
      form.data_nascimento = year + '-' + month + '-' + day
    }
    Swal.fire({
      title: 'Enviando dados!',
      timerProgressBar: true,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading()
      },
      didOpen: () => {
        api.updateProfile(form).then(
          data => {
            Swal.fire({
              title: 'Perfil atualizado com sucesso!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
            router.push({
              path: `/${user.get().role}/dashboard`,
            })
          },
          ({ errors = null, message }) => {
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              text: errors || message,
            })
          },
        )
      },
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
