import api from '@/api/api'
import Swal from 'sweetalert2'
import { getField, updateField } from 'vuex-map-fields'

export const BOOTSTRAP = 'BOOTSTRAP'
export const SET_STATE = 'SET_STATE'
export const DADOS_PEDIDO = 'DADOS_PEDIDO'
export const LOAD_BATCH = 'LOAD_BATCH'
export const EDIT_PEDIDO = 'EDIT_PEDIDO'
export const DELETE_PEDIDO = 'DELETE_PEDIDO'
export const HISTORICO_PEDIDO = 'HISTORICO_PEDIDO'
export const TRANSFERE_PEDIDO = 'TRANSFERE_PEDIDO'
export const ATUALIZAR_PEDIDO = 'ATUALIZAR_PEDIDO'
export const RESET_STATE = 'RESET_STATE'
export const UPDATE_PEDIDO_PRODUTO = 'UPDATE_PEDIDO_PRODUTO'

const getDefaultState = () => {
  return {
    ready: false,
    pedidos: [],
    pedido: {},
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [BOOTSTRAP] ({ dispatch, commit, state }, { data_inicial, data_final } = { data_inicial: 'from', data_final: 'to' }) {
    commit(SET_STATE, { ready: false })
    await dispatch(LOAD_BATCH, { data_inicial, data_final })
    commit(SET_STATE, { ready: true })
  },
  async [LOAD_BATCH] ({ dispatch, commit }, { data_inicial, data_final } = { data_inicial: 'from', data_final: 'to' }) {
    await api.listEntidade('pedido/' + data_inicial + '/' + data_final).then(
      pedidos => {
        pedidos.sort((a, b) => {
          const dataA = new Date(a.data_pedido)
          const dataB = new Date(b.data_pedido)
          return dataB - dataA
        })
        commit(SET_STATE, { pedidos })
      },
    )
  },
  async [DADOS_PEDIDO] ({ dispatch, commit }, id) {
    await api.listEntidade('pedido/' + id).then(
      pedido => {
        commit(SET_STATE, { ready: false })
        commit(SET_STATE, { pedido })
        commit(SET_STATE, { ready: true })
      },
    )
  },
  [EDIT_PEDIDO] ({ dispatch }, pedido) {
    dispatch('form/pedido/BOOTSTRAP_PROFILE', { id: pedido.id }, { root: true })
  },
  [ATUALIZAR_PEDIDO] ({ dispatch, state }, pedido) {
    state.pedidos.map(item => {
      if (item.id === pedido.id) item.status = pedido.status
    })
  },
  [HISTORICO_PEDIDO] ({ dispatch }, pedido) {
    dispatch('pedidologs/BOOTSTRAP', { id: pedido.id }, { root: true })
  },
  [TRANSFERE_PEDIDO] ({ dispatch }, pedido) {
    dispatch('pedidotransfere/BOOTSTRAP', { id: pedido.id }, { root: true })
  },

  async [DELETE_PEDIDO] ({ dispatch, state }, pedido) {
    return await Swal.fire({
      title: 'Excluir',
      text: 'Tem certeza que excluir o pedido?',
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        return api.inativarEntidade('pedido', pedido.id).then(
          result => result,
          () => {
            window.Toast.fire('Ocorreu algum erro na exclusão da Promoção!', '', 'error')
          },
        ).catch(() => {
          window.Toast.fire('Ocorreu algum erro na exclusão da Promoção!', '', 'error')
        })
      },
    }).then((result) => {
      if (result.isConfirmed) {
        window.Toast.fire('O pedido foi excluída com sucesso!', '', 'success')
      }
    })
  },

  async [UPDATE_PEDIDO_PRODUTO] ({ dispatch, state }, pedido) {
    Swal.fire({
      title: 'Atualizar',
      text: 'Tem certeza que deseja atualizar o pedido?',
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Atualizar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        return api.salvarEntidade(pedido, 'pedido/updateField', pedido.id).then(
          result => result,
          () => {
            window.Toast.fire('Ocorreu algum erro na atualização do Pedido!', '', 'error')
          },
        ).catch(() => {
          window.Toast.fire('Ocorreu algum erro na atualização do Pedido!', '', 'error')
        })
      },
    }).then((result) => {
      if (result.isConfirmed) {
        window.Toast.fire('O Pedido foi atualizado com sucesso!', '', 'success')
      }
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
