import api from '@/api/api'
import Swal from 'sweetalert2'
import { getField, updateField } from 'vuex-map-fields'
import { RegisterStatusText } from '../../utils/cadastro'
import { clone } from '../../utils/objectUtils'

export const BOOTSTRAP = 'BOOTSTRAP'
export const SET_STATE = 'SET_STATE'
export const SET_EDIT = 'SET_EDIT'
export const LOAD_CADASTRO = 'LOAD_CADASTRO'
export const DELETE_CADASTRO = 'DELETE_CADASTRO'
export const RESET_STATE = 'RESET_STATE'
export const UPDATE_STATUS = 'UPDATE_STATUS'
export const SEND_SMS = 'SEND_SMS'

const getDefaultState = () => {
  return {
    cadastros: null,
    copyCadastros: null,
    ready: false,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
}

const actions = {
  async [LOAD_CADASTRO] ({ commit }) {
    commit(SET_STATE, { ready: false })
    const cadastroData = (await api.listEntidade('register')).map(
      ({ ...rest }) => {
        const acc = {
          ...rest,
          statusText: RegisterStatusText[rest.status] ?? '',
        }
        return acc
      }, [],
    )
    commit(SET_STATE, { cadastros: cadastroData, copyCadastros: clone(cadastroData), ready: true })
  },
  async [BOOTSTRAP] ({ dispatch }) {
    await dispatch(LOAD_CADASTRO)
  },
  async [DELETE_CADASTRO] ({ state, dispatch }, cadastro) {
    const index = state.cadastros.findIndex(c => c.id === cadastro.id)

    if (cadastro.status === 'Em análise') {
      Swal.fire({
        title: 'Excluir',
        text: 'Tem certeza que deseja excluir a Solicitação de Conta?',
        icon: 'question',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonColor: '#109010',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        focusConfirm: false,
        preConfirm: () => {
          return api.inativarEntidade('register', cadastro.id).then(
            result => result,
            () => {
              Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: 'Não foi possivel excluir a Solicitação de Conta!',
              })
            },
          )
        },
      }).then(result => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Excluido',
            text: 'A Solicitação de Conta foi excluída com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 2500,
          })
          state.cadastros.splice(index, 1)
          dispatch(LOAD_CADASTRO)
        }
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Exclusão não permitida!',
        text: 'Solicitação de Conta com Status "Aprovado" não pode ser excluído do sistema!',
      })
    }
  },

  async [UPDATE_STATUS] ({ state, commit, dispatch }, cadastro) {
    Swal.fire({
      title: 'Atenção',
      text: 'Tem certeza que deseja atualizar a Solicitação de Conta?',
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        return api.salvarEntidade({
          status: cadastro.status,
        }, 'register/status', cadastro.id).catch(
          result => result,
          () => {
            Swal.fire({
              icon: 'error',
              title: 'Atenção',
              text: 'Não foi possivel atualizar a Solicitação de Conta!',
            })
          },
        )
      },
    }).then(result => {
      if (result.isConfirmed) {
        window.Toast.fire({
          icon: 'success',
          title: 'A Solicitação de Conta foi atualizada com sucesso!',
        })
        dispatch(BOOTSTRAP)
      } else {
        state.cadastros = clone(state.copyCadastros)
      }
    }).catch(() => {
      Swal.fire({
        icon: 'error',
        title: 'Atenção',
        text: 'Não foi possivel atualizar a Solicitação de Conta!',
      })
    })
  },

  async [SEND_SMS] ({ state, commit, dispatch }, cadastro) {
    Swal.fire({
      title: 'Enviar SMS',
      text: `Tem certeza que deseja enviar SMS para o ${cadastro.nome}, no número ${cadastro.celular}.`,
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Enviar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        return api.getEntidade('register/send-sms', cadastro.id).catch(
          result => result,
          () => {
            Swal.fire({
              icon: 'error',
              title: 'Atenção',
              text: `Não foi possivel enviar SMS para o ${cadastro.nome}!`,
            })
          },
        )
      },
    }).then(result => {
      if (result.isConfirmed) {
        window.Toast.fire({
          icon: 'success',
          title: 'SMS enviado com sucesso!',
        })

        dispatch(LOAD_CADASTRO)
      }
    }).catch(() => {
      Swal.fire({
        icon: 'error',
        title: 'Atenção',
        text: `Não foi possivel enviar SMS para o ${cadastro.nome}!`,
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
