export const statusPedido = {
  nao_sincronizado: 'Não Sincronizado',
  aceite_produtor: 'Aguardando Aceite Produtor',
  aguardando_aprovacao: 'Aguardando Aprovação',
  em_analise: 'Em Análise',
  aprovado: 'Aprovado',
  cancelamento_solicitado: 'Cancelamento Solicitado',
  cancelado: 'Cancelado',
  faturado: 'Faturado',
  entregue_tecnico: 'Entregue ao Técnico',
  entregue_transportadora: 'Entregue à Transportadora',
  entregue_produtor: 'Entregue ao Produtor',
}

export const statusCotacao = {
  aguardando_cotacao: 'Aguardando Cotação',
  aceite_produtor: 'Aguardando Aceite Produtor',
  contra_produtor: 'Contraproposta Produtor',
  aprovado: 'Aprovado',
  cancelado: 'Cancelado',
  cancelamento_solicitado: 'Cancelamento Solicitado',
  nao_sincronizado: 'Não Sincronizado',
  em_analise: 'Em Análise',
}

export const statusPedidoFit = {
  aprovado: statusPedido.aprovado,
  cancelado: statusPedido.cancelado,
  faturado: statusPedido.faturado,
  entregue_tecnico: statusPedido.entregue_tecnico,
  entregue_transportadora: statusPedido.entregue_transportadora,
  entregue_produtor: statusPedido.entregue_produtor,
  cancelamento_solicitado: statusPedido.cancelamento_solicitado,
  em_analise: statusPedido.em_analise,
  aguardando_aprovacao: statusPedido.aguardando_aprovacao,
  aceite_produtor: statusPedido.aceite_produtor,
}

export const statusCotacaoFit = {
  aprovado: statusCotacao.aprovado,
  cancelado: statusCotacao.cancelado,
  em_analise: statusCotacao.em_analise,
}

export const statusPedidoString = Object.values(statusPedido)
export const statusCotacaoString = Object.values(statusCotacao)

export const statusPedidoFitString = Object.values(statusPedidoFit)
export const statusCotacaoFitString = Object.values(statusCotacaoFit)

export const statusPedidoColor = (status) => {
  switch (status) {
    case statusPedido.nao_sincronizado:
      return '#A07EFF'
    case statusPedido.aceite_produtor:
      return '#2DADED'
    case statusPedido.aguardando_aprovacao:
      return '#1C4EFF'
    case statusPedido.em_analise:
      return '#D39942'
    case statusPedido.aprovado:
      return '#44BC58'
    case statusPedido.cancelamento_solicitado:
      return '#FF967E'
    case statusPedido.cancelado:
      return '#E40E0E'
    case statusPedido.faturado:
      return '#184294'
    case statusPedido.entregue_tecnico:
      return '#79adb4'
    case statusPedido.entregue_transportadora:
      return '#188594'
    case statusPedido.entregue_produtor:
      return '#167955'
    default:
      return '#979797'
  }
}

export const statusPedidoIcon = (status) => {
  switch (status) {
    case statusPedido.nao_sincronizado:
      return 'mdi-file-alert-outline'
    case statusPedido.aceite_produtor:
      return 'mdi-file-star-outline'
    case statusPedido.aguardando_aprovacao:
      return 'mdi-file-clock-outline'
    case statusPedido.em_analise:
      return 'mdi-book-clock-outline'
    case statusPedido.aprovado:
      return 'mdi-file-check-outline'
    case statusPedido.cancelamento_solicitado:
      return 'mdi-file-remove-outline'
    case statusPedido.cancelado:
      return 'mdi-file-cancel-outline'
    case statusPedido.faturado:
      return 'mdi-clipboard-check-outline'
    case statusPedido.entregue_tecnico:
      return 'mdi-file-account'
    case statusPedido.entregue_transportadora:
      return 'mdi-file-plus-outline'
    case statusPedido.entregue_produtor:
      return 'mdi-book-check-outline'
    default:
      return 'mdi-book-check-outline'
  }
}

export const statusCotacaoColor = (status) => {
  switch (status) {
    case statusCotacao.aprovado:
      return '#44BC58'
    case statusCotacao.aguardando_cotacao:
      return '#184294'
    case statusCotacao.aceite_produtor:
      return '#2DADED'
    case statusCotacao.contra_produtor:
      return '#1C4EFF'
    case statusCotacao.em_analise:
      return '#D39942'
    case statusCotacao.cancelado:
      return '#B82200'
    case statusCotacao.cancelamento_solicitado:
      return '#F25936'
    case statusCotacao.nao_sincronizado:
      return '#A07EFF'
    default:
      return '#979797'
  }
}

export const statusCotacaoIcon = (status) => {
  switch (status) {
    case statusCotacao.aprovado:
      return 'mdi-file-check-outline'
    case statusCotacao.aguardando_cotacao:
      return 'mdi-file-clock-outline'
    case statusCotacao.aceite_produtor:
      return 'mdi-file-star-outline'
    case statusCotacao.contra_produtor:
      return 'mdi-clipboard-check-outline'
    case statusCotacao.em_analise:
      return 'mdi-book-clock-outline'
    case statusCotacao.cancelado:
      return 'mdi-file-cancel-outline'
    case statusCotacao.cancelamento_solicitado:
      return 'mdi-file-remove-outline'
    case statusCotacao.nao_sincronizado:
      return 'mdi-file-alert-outline'
    default:
      return ''
  }
}

export const relatorios = {
  vendasUnidadesNegocio: 'vendas-unidades-negocio',
  vendasTecnicos: 'vendas-tecnicos',
  vendasProdutores: 'vendas-produtores',
  desempenhoPromocoes: 'desempenho-promocoes',
  volumeLeiteXProdutosNutricao: 'volume-leite-x-produtos-nutricao',
  produtosMaisVendidos: 'produto-mais-vendido',
  produtoresSemCompra: 'produtores-sem-compra',
  produtoresFazendas: 'produtores-fazendas',
  acompanhamentoSemanalVendas: 'acompanhamento-semanal-vendas',
}

export const relatoriosString = Object.values(relatorios)

export const StatusLog = {
  EM_ANDAMENTO: 'EM ANDAMENTO',
  FINALIZADO: 'FINALIZADO',
  CANCELADO: 'CANCELADO',
  ERROR: 'ERROR',
}

export const checkDatePedido = (date) => {
  if (date) {
    const proximoDia = new Date(date.substring(0, 10) + 'T' + date.substring(11, 19) + '.000Z')
    const data_now = new Date()

    // Avança para o próximo dia até encontrar um dia útil (segunda a sexta)
    do {
      proximoDia.setDate(proximoDia.getDate() + 1)
    } while (proximoDia.getDay() === 0 || proximoDia.getDay() === 6)

    if (data_now.toISOString().slice(0, 10) <= proximoDia.toISOString().slice(0, 10)) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

export const checkDateCotacao = (date) => {
  if (date) {
    let proximoDia = new Date(date.substring(0, 10) + 'T' + date.substring(11, 19) + '.000Z')
    const data_now = new Date()
    let diasUteisContados = 0

    // Conta 5 dias úteis a partir da data fornecida
    while (diasUteisContados < 5) {
      proximoDia.setDate(proximoDia.getDate() + 1)

      // Verifica se o dia é útil (segunda a sexta)
      if (proximoDia.getDay() !== 0 && proximoDia.getDay() !== 6) {
        diasUteisContados++
      }
    }

    // Verifica se a data atual é menor ou igual à data calculada de 5 dias úteis
    if (data_now.toISOString().slice(0, 10) <= proximoDia.toISOString().slice(0, 10)) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}
